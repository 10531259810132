<script setup lang="ts">
import type { INavLinkItem } from './NavigationLinks.vue'
import { useIsSubdomain } from '~/composables/useSubdomain'

const props = withDefaults(
  defineProps<{
    logoImg?: string
    extraLinkItems?: INavLinkItem[]
  }>(),
  { logoImg: '/_nuxt3-static/logos/logo.svg', extraLinkItems: () => [] }
)

const isSubdomain = useIsSubdomain()
const { subdomainData: partnerData } = await useSubdomainData()

const isNavSidebarOpen = ref(false)

const toggleNavSidebar = () =>
  (isNavSidebarOpen.value = !isNavSidebarOpen.value)

const isMounted = ref(false)

onMounted(() => {
  isMounted.value = true
})

const defaultLinks = [
  { path: '/search', text: 'general.allEvents' },
  { path: '/giftcard', text: 'general.giftCards', showOnSub: true },
  { path: '/teamevent', text: 'general.teamEvents' },
  { path: '/partner', text: 'general.beAPartner' },
  { path: '/magazine', text: 'general.magazine' },
] as INavLinkItem[]

if (isSubdomain.value) {
  defaultLinks.unshift({
    path: '/',
    text: 'general.allEvents',
    showOnSub: true,
  })
}

if (props.extraLinkItems && Array.isArray(props.extraLinkItems)) {
  defaultLinks.push(...props.extraLinkItems)
}

const navLinks = computed(() => {
  const links = defaultLinks.filter((l) => !isSubdomain.value || l.showOnSub)
  if (!supplierGetters.getHasGiftcardEmbedded(partnerData.value)) {
    return links.filter((l) => l.path !== '/giftcard')
  }
  return links
})
</script>

<template>
  <div>
    <NuxtLazyHydrate when-idle>
      <CartSidebar />
    </NuxtLazyHydrate>

    <nav class="bg-white border-b sm:border-b-0 border-gray-200">
      <div
        class="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto px-4 py-2 h-[74px]"
      >
        <HamburgerButton @navbar-open="toggleNavSidebar" />
        <NuxtLinkLocale
          :to="'/'"
          class="flex items-center space-x-3 rtl:space-x-reverse"
        >
          <img :src="logoImg" class="h-14" alt="konfetti logo" />
        </NuxtLinkLocale>
        <NuxtLazyHydrate when-idle>
          <NavbarUserMenu :hide-account-icon="true" />
        </NuxtLazyHydrate>
      </div>
    </nav>

    <NuxtLazyHydrate when-idle>
      <NavigationLinks
        :is-open="isNavSidebarOpen"
        :nav-links="navLinks"
        @navbar-close="toggleNavSidebar"
      />
    </NuxtLazyHydrate>
  </div>
</template>
